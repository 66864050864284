const padding = {
  outsideElements: {
    single: "2vmin",
    double: "4vmin",
    quadruple: "8vmin",
    octuple: "16vmin",
  },
  insideElements: {
    quarter: "0.25rem",
    half: "0.5rem",
    threequarters: "0.75rem",
    single: "1rem",
    double: "2rem",
    tripple: "3rem",
    quadruple: "4rem",
  },
};

export default padding;
