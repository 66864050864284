const typography = {
  minSize: 1,
  maxSize: 1.25,
  lh: 1.6, // base line-height
  maxHMod: 0.25,
  minHMod: 0.125,
  maxBMod: 0.25,
  minBMod: 0.125,
  inc: 0.1, // line height modifier
  ftMin: 560,
  ftMax: 1440,
  headlineFont: `'Mona Sans', sans-serif`,
  bodyFont: `'Mona Sans', sans-serif`,
};

export default typography;
