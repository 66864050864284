const sizes = {
  small: "18vh",
  medium: "36vh",
  large: "48vh",
  xl: "92vh",
  contentWidthLimit: 1280,
  sectionHeaderWidthLimit: 400,
};

export default sizes;
